<template>
  <el-card id="departmentNumberApply">
    <header>
      <h1>部门海号码申请</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业名称</span>
            <el-input
              v-model="company"
              placeholder="请输入企业名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户号码</span>
            <el-input
              v-model="activePhone"
              placeholder="请输入客户号码"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>号码状态</span>
            <el-select v-model="p_status" placeholder="请选择状态" size="small">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>申请状态</span>
            <el-select v-model="f_status" placeholder="请选择状态" size="small">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="applyAll"
            size="small"
            class="reset"
            >批量申请</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column
        ><el-table-column
          prop="company"
          label="企业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="active_phone"
          label="客户号码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customer_phone"
          label="客户姓名"
          align="center"
        ></el-table-column>
        <el-table-column prop="p_status" label="号码状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.p_status == 0">一般客户</span>
            <span v-if="scope.row.p_status == 1">意向客户</span>
            <span v-if="scope.row.p_status == 2">无意向客户</span>
            <span v-if="scope.row.p_status == 3">成交客户</span>
            <span v-if="scope.row.p_status == 4">黑名单</span>
          </template>
        </el-table-column>
        <el-table-column prop="f_status" label="申请状态" align="center">
          <template v-slot="scope">
            <div :class="fontClass(scope.row.f_status)">
              {{ statusClass(scope.row.f_status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template v-slot="scope">
            <el-button
              v-show="!distinguishStatus(scope.row.f_status)"
              type="primary"
              plain
              disabled
              @click="apply(scope.row)"
              size="small"
              >申 请</el-button
            >
            <el-button
              v-show="distinguishStatus(scope.row.f_status)"
              type="primary"
              @click="apply(scope.row)"
              size="small"
              style="margin-left: 0"
              >申 请</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 申请弹出框 -->
    <el-dialog title="申请" :visible.sync="applyVisible" width="30%">
      <span>确定申请这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="applyVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitApply()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量申请弹出框 -->
    <el-dialog title="批量申请" :visible.sync="applyAllVisible" width="30%">
      <span>确定申请这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="applyAllVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitApplyAll()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  phoneApplyForList,
  phoneApplyUserPhoneApplyFor,
} from "@/api/phoneApplyFor";
export default {
  data() {
    return {
      applyVisible: false,
      applyAllVisible: false,
      company: null,
      activePhone: null,
      p_status: null,
      f_status: null,
      numsArray: [],
      options: [
        {
          value: 0,
          label: "一般客户",
        },
        {
          value: 1,
          label: "意向客户",
        },
        {
          value: 2,
          label: "无意向客户",
        },
        {
          value: 3,
          label: "成交客户",
        },
        {
          value: 4,
          label: "黑名单",
        },
        {
          value: 5,
          label: "无状态",
        },
        {
          value: 6,
          label: "未接通",
        },
      ],
      options1: [
        {
          value: null,
          label: "可申请",
        },
        {
          value: 0,
          label: "申请待审核",
        },
        {
          value: 1,
          label: "申请通过",
        },
        {
          value: 2,
          label: "申请驳回",
        },
      ],
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 按钮状态
    distinguishStatus(f_status) {
      if (f_status == null || f_status == 2) {
        return true;
      }
    },
    // 状态分类
    statusClass(f_status) {
      if (f_status == null) {
        return "未申请";
      } else if (f_status == 0) {
        return "待审核";
      } else if (f_status == 1) {
        return "审核通过";
      } else if (f_status == 2) {
        return "审核未通过";
      }
    },
    // 状态分类字体样式
    fontClass(f_status) {
      if (f_status == null) {
        return "f_statusn";
      } else if (f_status == 0) {
        return "f_status0";
      } else if (f_status == 1) {
        return "f_status1";
      } else if (f_status == 2) {
        return "f_status2";
      }
    },
    // 申请
    apply(data) {
      let userId = JSON.parse(localStorage.getItem("id"));
      let params = {
        listId: [data.pid],
        userId,
      };
      this.applyParams = params;
      this.applyVisible = true;
    },
    // 确认申请
    submitApply() {
      let res = phoneApplyUserPhoneApplyFor(this.applyParams);
      res.then((res) => {
        Message.success(res.data.message);
        this.applyVisible = false;
        this.getList();
      });
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.pid);
      });
    },
    // 批量申请
    applyAll() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.applyAllVisible = true;
    },
    // 确认批量申请
    submitApplyAll() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.p_status == 4) {
            Message.warning("选中列表中包含黑名单，无法申请");
            return;
          }
        });
      });
      let userId = JSON.parse(localStorage.getItem("id"));
      let params = {
        listId: this.numsArray,
        userId,
      };
      let res = phoneApplyUserPhoneApplyFor(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.applyAllVisible = false;
      });
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.company = null;
      this.activePhone = null;
      this.p_status = null;
      this.f_status = null;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        company: this.company,
        activePhone: this.activePhone,
        p_status: this.p_status,
        f_status: this.f_status,
        page: this.page,
        size: this.size,
        roleDepart: 1,
      };
      let res = phoneApplyForList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#departmentNumberApply {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
    .f_statusn {
      color: #909399;
    }
    .f_status0 {
      color: #e6a23c;
    }
    .f_status1 {
      color: #67c23a;
    }
    .f_status2 {
      color: #f56c6c;
    }
  }
}
</style>